$cam: #e49b4d;
$dbn: #695250;
$nav: #232038;
$kha: #5e5942;
$blk: #000000;
$wht: #ffffff;
$yel: #f7d159;
$dgr: #999594;
$lkh: #808971;

.bg-cam {
	background-color: $cam !important;
}
.bg-dbn {
	background-color: $dbn !important;
}
.bg-nav {
	background-color: $nav !important;
}
.bg-kha {
	background-color: $kha !important;
}
.bg-blk {
	background-color: $blk !important;
}
.bg-wht {
	background-color: $wht !important;
}
.bg-yel {
	background-color: $yel !important;
}
.bg-lkh {
	background-color: $lkh !important;
}
.bg-dgr {
	background-color: $dgr !important;
}

.carousel-indicators [data-bs-target] {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid #707070;
	opacity: 1;
}

.carousel-indicators [data-bs-target].active {
	-webkit-box-shadow: 0 0 7px 2px #707070;
	box-shadow: 0 0 7px 2px #707070;
}

.carousel-indicators {
	margin-bottom: -1rem;
}
