@import 'typography';
@import 'colors';

/*
* {
 outline: 1px solid red;
}
*/

.hidden {
	display: none;
}

a {
	color: #8a8c49;
	text-decoration: none;
}
a:link,
a:visited,
a:hover,
a:active {
	text-decoration: none;
	color: #8a8c49;
}

a.btn-primary,
.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled):active {
	background-color: #8a8c49;
	border-color: #8a8c49;
	color: #ffffff;
	font-weight: 700;
}
a.btn-primary:hover,
.btn-primary:hover {
	background-color: #8a8c49;
	border-color: #8a8c49;
	color: #ffffff;
	font-weight: 700;
}
.btn-primary.disabled,
.btn-primary:disabled {
	color: #000000;
	background-color: #8a8c49;
	border-color: #8a8c49;
}

.dropdown-menu[data-bs-popper] {
	right: 0;
	left: auto;
}

.card {
	border-color: transparent;
}
.card-collection {
	position: relative;
	border-radius: 0px;
}
.card-collection .km {
	position: absolute;
	right: 0;
	top: 0;
	font-family: 'Trim-Bold';
	opacity: 0.15;
	text-transform: uppercase;
}
.card-collection .km span {
	display: block;
}
.card-collection .km span:first-child {
	font-size: 40vw;
	line-height: 40vw;
	margin: 0;
	padding: 0;
}
.card-collection .km span:last-child {
	font-size: 25vw;
	line-height: 10vw;
	margin: 0;
	padding: 0;
}
.card-collection .collection {
	font-family: 'Trim-Bold';
	opacity: 0.15;
	text-transform: uppercase;
	font-size: 15vw;
	line-height: 1;
	margin-top: 20px;
}
.card-collection img {
	padding: 5rem 2rem 0 2rem;
	z-index: 999;
}

@media only screen and (min-width: 573px) {
	.card-collection .km span:first-child {
		font-size: 10vw;
		line-height: 10vw;
	}
	.card-collection .km span:last-child {
		font-size: 5vw;
		line-height: 2vw;
	}
}

@media only screen and (min-width: 576px) {
	.card-collection .collection {
		font-size: 5vw;
	}
}

.card-article {
	position: relative;
	border-radius: 0px;
	background-color: #fafafa;
}
.card-article .km {
	position: absolute;
	right: 0;
	top: 0;
	font-family: 'Trim-Bold';
	text-transform: uppercase;
	text-align: center;
	z-index: 2;
}
.card-article .km span {
	display: block;
}
.card-article .km span:first-child {
	font-size: 2rem;
	line-height: 2rem;
	margin: 0;
	padding: 0;
}
.card-article .km span:last-child {
	font-size: 1rem;
	line-height: 1rem;
	margin: 0;
	padding: 0;
}

.card-article {
	border: 0;
}

.card-article.border-city,
.card-article.border-mountain,
.card-article.border-forest {
	border-bottom: 10px solid;
}

.card-article li img {
	width: 30px;
}

.card-momo {
	position: relative;
}
.photos {
	display: none;
}
@media only screen and (min-width: 573px) {
	.card-momo {
		position: absolute;
		bottom: -30px;
		width: 90%;
		left: 50%;
		transform: translate(-50%, 0);
	}
}
@media only screen and (min-width: 768px) {
	.card-momo {
		position: absolute;
		left: 52%;
		bottom: -30px;
		width: calc(100vw - 60%);
		transform: translate(-10%, 0);
	}
	.photos {
		position: absolute;
		bottom: calc(0% - 25vh);
		left: 5vw;
		width: 40vw;
		display: inline-block;
	}
}
@media only screen and (min-width: 1320px) {
	.card-momo {
		position: absolute;
		left: 70%;
		bottom: -30px;
		width: calc(100vw - 80%);
		transform: translate(-10%, 0);
	}
	.photos {
		position: absolute;
		bottom: calc(0% - 32vh);
		left: 10vw;
		width: 32vw;
		display: inline-block;
	}
}

/* HEADER */
header {
	color: $wht;
}
.campaign-banner__figure video#teaserVideo {
	position: relative;
	z-index: 1;
	width: 100% !important;
	height: auto !important;
	top: 0;
	left: 0;
}
.campaign-banner__figure video#mainVideo {
	position: relative;
	z-index: 0;
	width: 100% !important;
	height: auto !important;
	top: 0;
	left: 0;
	display: none;
}
.campaign-banner__figure #overlay_teaserVideo {
	position: absolute;
	z-index: 3;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	cursor: pointer;
}
.campaign-banner__figure #overlay_mainVideo {
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	cursor: pointer;
	visibility: hidden;
}

.header div.highlight {
	background-color: #000;
	color: #fff;
	border-radius: 3px;
	font-weight: bold;
}

@media only screen and (min-width: 573px) {
	.header h1 {
		font-size: 3rem;
	}
	.header p {
		font-size: 1.75rem;
		width: 75vw;
		line-height: 1.75rem;
	}
}
.header-img {
	background-position: 68% bottom;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(80vh);
}
.header-img-small {
	background-position: 50% top;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(60vh);
}
.header-img-smaller {
	background-position: 50% top;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(30vh);
}

@media screen and (orientation: portrait) {
	.header-img {
		background-position: 50% bottom;
		background-repeat: no-repeat;
		background-size: cover;
		height: calc(50vh);
	}
}
@media screen and (orientation: landscape) {
	.header-img {
		background-position: 68% bottom;
		background-repeat: no-repeat;
		background-size: cover;
		height: calc(60vh);
	}
}
@media only screen and (min-width: 768px) {
	.header-img {
		background-position: 68% bottom;
		background-repeat: no-repeat;
		background-size: cover;
		height: calc(30vh + 100vw / 6);
	}
	.header-img-small {
		background-position: 50% top;
		background-repeat: no-repeat;
		background-size: cover;
		height: calc(25vh + 100vw / 8);
	}
	.header-img-smaller {
		background-position: 50% top;
		background-repeat: no-repeat;
		background-size: cover;
		height: calc(10vh + 100vw / 8);
	}
}

.cover-img {
	background-position: 50% bottom;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(30vh + 110vw / 8);
}


/* FOOTER */
footer {
	background-color: #8a8c49;
	color: #ffffff;
}
footer a,
footer a:link,
footer a:visited,
footer a:hover,
footer a:active {
	color: #ffffff;
}
footer .nav-link {
	text-transform: lowercase
}
footer .nav-link:first-letter {
 text-transform: uppercase;
}
footer .nav-link:focus, footer .nav-link:hover {
 color: #ccc;
}

@media only screen and (min-width: 768px) {
	footer {
		background-color: #8a8c49;
		background-image: url('/assets/img/quickstep.jpg');
		background-repeat: no-repeat;
		background-position: top right;
		-o-background-size:50% auto;
		-webkit-background-size:50% auto;
		-moz-background-size:50% auto;
		background-size:50% auto;
	}
}

@media only screen and (min-width: 1500px) {
	footer {
		-o-background-size:40% auto;
		-webkit-background-size:40% auto;
		-moz-background-size:40% auto;
		background-size:40% auto;
	}
}

.carousel-caption {
	top: 0;
	padding-top: 0;
}

/* COLORS */
.border-city {
	border-color: #4e738a !important;
}
.border-mountain {
	border-color: #205a41 !important;
}
.border-forest {
	border-color: #e64b38 !important;
}
.white {
	color: #ffffff;
}
.city  {
	color: #4e738a;
}
.application-city .bg-collection,
.bg-city {
	color: #ffffff;
	background-color: #4e738a;
}
.forest {
	color: #e64b38;
}
.application-forest .bg-collection,
.bg-forest {
	color: #ffffff;
	background-color: #e64b38;
}
.mountain {
	color: #205a41;
}
.application-mountain .bg-collection,
.bg-mountain {
	color: #ffffff;
	background-color: #205a41;
}
.adventure {
	color: #8a8c49;
}
.bg-adventure {
	background-color: #8a8c49;
}

/* NAVBAR */
//@media only screen and (min-width: 992px) {
//	.navbar {position: fixed;top: 0;right: 0;left: 0;z-index: 1030;}
//}
.scrolled-down {
	transform: translateY(-100%);
	transition: all 0.3s ease-in-out;
}
.scrolled-up {
	transform: translateY(0);
	transition: all 0.3s ease-in-out;
}
.nav-link {
	text-transform: uppercase;
}

.ms-6 {
	margin-left: 4rem !important;
}

.ms-7 {
	margin-left: 5rem !important;
}

.ms-8 {
	margin-left: 6rem !important;
}

.ps-6,
.px-6,
.p-6 {
	padding-left: 4rem !important;
}

.ps-7,
.px-7,
.p-7 {
	padding-left: 5rem !important;
}

.ps-8,
.px-8,
.p-8 {
	padding-left: 6rem !important;
}

.pr-6,
.px-6,
.p-6 {
	padding-right: 4rem !important;
}

.pr-7,
.px-7,
.p-7 {
	padding-right: 5rem !important;
}

.pr-8,
.px-8,
.p-8 {
	padding-right: 6rem !important;
}

.pt-6,
.py-6,
.p-6 {
	padding-top: 4rem !important;
}

.pt-7,
.py-7,
.p-7 {
	padding-top: 5rem !important;
}

.pt-8,
.py-8,
.p-8 {
	padding-top: 6rem !important;
}

.pb-6,
.py-6,
.p-6 {
	padding-bottom: 4rem !important;
}

.pb-7,
.py-7,
.p-7 {
	padding-bottom: 5rem !important;
}

.pb-8,
.py-8,
.p-8 {
	padding-bottom: 6rem !important;
}

.mb-6 {
	margin-bottom: 4rem !important;
}

.mt-6 {
	margin-top: 4rem !important;
}
