
@font-face {
	font-family:'Trim-Regular';
 src:url('../fonts/Trim-Regular.eot');
	src:local('☺'), url('../fonts/Trim-Regular.woff') format('woff'), url('../fonts/Trim-Regular.ttf') format('truetype'), url('../fonts/Trim-Regular.svg') format('svg');
	font-weight:normal;
	font-style:normal;
}
@font-face {
	font-family:'Trim-Medium';src:url('../fonts/Trim-Medium.eot');src:local('☺'), url('../fonts/Trim-Medium.woff') format('woff'), url('../fonts/Trim-Medium.ttf') format('truetype'), url('../fonts/Trim-Medium.svg') format('svg');
	font-weight:normal;
	font-style:normal;
}
@font-face {
	font-family:'Trim-Bold';src:url('../fonts/Trim-Bold.eot');src:local('☺'), url('../fonts/Trim-Bold.woff') format('woff'), url('../fonts/Trim-Bold.ttf') format('truetype'), url('../fonts/Trim-Bold.svg') format('svg');
	font-weight:normal;
	font-style:normal;
}


h1, .h1 {font-family: 'Trim-Bold';}
h2, .h2 {font-family:'Trim-Medium';}
h3, .h3 {font-family: 'Trim-Bold';}
h4, .h4 {font-family: 'Trim-Medium';}
h4, .h4 {font-family: 'Trim-Medium';}
h5, .h5 {font-family: 'Trim-Medium';}
p.lead {font-weight:bold;}
.text-shadow {text-shadow:.075rem .075rem .075rem rgba(0,0,0,0.5);}
nav {font-family: 'Trim-Medium';}
